.styles-module__nTF3mW__title {
  color: var(--wcom-c-blueDark);
  gap: 5px;
  margin-bottom: 10px;
  font-size: 12px;
  display: flex;
}

.styles-module__nTF3mW__text, .styles-module__nTF3mW__text > * {
  font-size: 12px;
  line-height: normal;
}

.styles-module__nTF3mW__text > * a {
  color: var(--wcom-c-blueDark);
}

.styles-module__nTF3mW__imageLogo {
  float: right;
}

.styles-module__nTF3mW__imageLivespotting {
  width: 40vw;
  margin: 10px 0;
}

.styles-module__nTF3mW__container {
  margin-top: 10px;
  display: none;
}

.styles-module__nTF3mW__containerExpanded {
  display: block;
}

.styles-module__nTF3mW__arrow {
  font-size: 8px;
}

.styles-module__nTF3mW__arrowExpanded {
  transform: rotate(180deg);
}

.styles-module__G23o0q__wrapper {
  height: -moz-fit-content;
  height: fit-content;
}

.styles-module__8_xcga__title {
  all: unset;
  color: var(--wcom-c-blueDark);
  margin-bottom: 10px;
  font-size: 12px;
}

.styles-module__8_xcga__playerWrapper, .styles-module__8_xcga__inactiveMessage {
  aspect-ratio: auto 16 / 9;
  margin-bottom: 20px;
}

.styles-module__8_xcga__inactiveMessage {
  background-color: var(--wcom-c-black);
  color: var(--wcom-c-white);
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  display: flex;
}

.styles-module__8_xcga__stickyContainer {
  z-index: calc(var(--wcom-z-autosuggest)  - 1);
  width: 100vw;
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
}

.styles-module__8_xcga__topAd {
  top: 125px;
}

.styles-module__8_xcga__textWrapper, .styles-module__8_xcga__textWrapper > * {
  font-size: 12px;
  line-height: normal;
}

.styles-module__8_xcga__textWrapper > * a {
  color: var(--wcom-c-blueDark);
}

.styles-module__8_xcga__imageLeft {
  width: 40vw;
  margin: 10px 0;
}

.styles-module__8_xcga__imageRight {
  float: right;
  width: 20vw;
  margin-left: 5px;
}

.styles-module__8_xcga__imageContainer {
  position: relative;
}

.styles-module__8_xcga__image {
  position: absolute;
}

.styles-module__uqsRjW__headline {
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 32px;
}

.styles-module__uqsRjW__textWrapper > * {
  font-size: 12px;
  line-height: normal;
}

.styles-module__uqsRjW__divider {
  border-top: 1px solid var(--wcom-c-gray);
  margin-bottom: 20px;
}

.styles-module__16SbLW__button {
  cursor: pointer;
  height: 40px;
  font: inherit;
  text-align: center;
  border: none;
  border-radius: 2px;
  outline: 3px solid #0000;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  padding: 14px 24px;
  font-size: 16px;
  font-weight: 600;
  display: inline-flex;
}

.styles-module__16SbLW__button:first-child {
  margin-left: 0;
}

.styles-module__16SbLW__button:last-child {
  margin-right: 0;
}

.styles-module__16SbLW__fullWidth {
  width: 100%;
}

.styles-module__16SbLW__primary {
  background: var(--wcom-c-orangeLighter);
  color: var(--wcom-c-blueDark);
}

.styles-module__16SbLW__secondary {
  background: linear-gradient(0deg, var(--wcom-c-blueMidDark), var(--wcom-c-blue));
  color: var(--wcom-c-white);
}

.styles-module__16SbLW__tertiary {
  border: var(--wcom-c-orangeLighter) 1px solid;
  background: var(--wcom-c-white);
  color: var(--wcom-c-blueDark);
}

.styles-module__16SbLW__snowButton, .styles-module__16SbLW__snowPrimary {
  cursor: pointer;
  text-align: center;
  border-radius: 32px;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 14px 24px;
  display: inline-flex;
}

.styles-module__16SbLW__snowPrimary {
  background-color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-primary-secondary-light)) var(--lightningcss-dark, var(--wcom-c-on-primary-secondary-dark));
}

.content-module__XBP5Dq__wrapper {
  flex-direction: column;
  gap: 40px;
  display: flex;
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
}

.content-module__XBP5Dq__dialog {
  z-index: var(--wcom-z-newDataUpdate);
  filter: none;
  background: var(--wcom-c-white);
  width: 300px;
  color: var(--wcom-c-blueDark);
  text-align: center;
  align-self: center;
  position: relative;
}

.content-module__XBP5Dq__inner {
  padding: 50px 20px 20px;
}

.content-module__XBP5Dq__warningIcon {
  background: var(--wcom-c-orangeLighter);
  width: 52.5px;
  height: 52.5px;
  color: var(--wcom-c-white);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  display: flex;
  position: absolute;
  top: -26.25px;
  left: 50%;
  transform: translateX(-50%);
}

.content-module__XBP5Dq__reload {
  margin-top: 20px;
}

.content-module__XBP5Dq__message {
  margin: 0;
  font-size: 15px;
  line-height: 23px;
}

.content-module__XBP5Dq__link {
  background: var(--wcom-c-blueMidDark);
  color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  font-size: 15px;
  display: flex;
}

.content-module__XBP5Dq__link span {
  margin-left: 5px;
}

.filterLightbox-module__u-nXQa__wrapper {
  background-color: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  padding: 12px 12px 80px;
  position: absolute;
  overflow-y: scroll;
}

@supports (height: 100dvh) {
  .filterLightbox-module__u-nXQa__wrapper {
    height: 100dvh;
    max-height: calc(100dvh - 130px);
  }
}

@supports not (height: 100dvh) {
  .filterLightbox-module__u-nXQa__wrapper {
    height: 100%;
    max-height: calc(100% - 130px);
  }
}

.filterLightbox-module__u-nXQa__headline {
  margin-top: 5px;
  font-size: 22px;
  line-height: 28px;
}

.filterLightbox-module__u-nXQa__subHeadline {
  margin: 24px 0 20px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
}

.filterLightbox-module__u-nXQa__filterWrapper {
  flex-wrap: wrap;
  gap: 8px;
  display: flex;
}

.filterLightbox-module__u-nXQa__countrySelect {
  position: relative;
}

.filterLightbox-module__u-nXQa__select {
  --wcom-border-color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  border: 1px solid var(--wcom-border-color);
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 14px 12px;
  display: flex;
}

.filterLightbox-module__u-nXQa__select span[class^="icon-"] {
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
  font-size: 8px;
}

.filterLightbox-module__u-nXQa__selectOpen {
  border-radius: 0 0 8px 8px;
}

.filterLightbox-module__u-nXQa__selectOpen span[class^="icon-"] {
  transform: rotate(180deg);
}

.filterLightbox-module__u-nXQa__dropdown {
  --wcom-border-color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  border: 1px solid var(--wcom-border-color);
  background-color: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  border-radius: 8px 8px 0 0;
  width: 100%;
  max-height: 240px;
  position: absolute;
  bottom: 49px;
  overflow-y: scroll;
}

.filterLightbox-module__u-nXQa__dropdownItem {
  padding: 16px 12px;
}

.filterLightbox-module__u-nXQa__buttonContainer {
  background-color: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  padding: 10px;
  position: fixed;
  left: 0;
  right: 0;
}

.filterLightbox-module__u-nXQa__removeFilterButton {
  all: unset;
  width: 100%;
  height: 48px;
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
  text-align: center;
  margin-top: 10px;
  font-size: 15px;
  border: none !important;
}

:root {
  --wcom-font-size-mobile: 12px;
  --wcom-line-height-mobile: 16px;
}

.index-module__XwIKRG__h3 {
  height: 40px;
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  margin: 0;
  padding: 10px;
  font-size: 18px;
}

.index-module__XwIKRG__noResultsContainer {
  padding: 30px 10px;
}

.index-module__XwIKRG__resetFilterButton {
  margin-top: 10px;
}

.index-module__XwIKRG__item {
  --wcom-border-color: var(--lightningcss-light, var(--wcom-c-outline-variant-light)) var(--lightningcss-dark, var(--wcom-c-outline-variant-dark));
  border: 1px solid var(--wcom-border-color);
  height: 48px;
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  white-space: nowrap;
  border-radius: 8px;
  align-items: center;
  gap: 4px;
  padding: 12px;
  display: flex;
}

.index-module__XwIKRG__item span[class^="icon-"], .index-module__XwIKRG__item span[class^="snow-icon-"] {
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
}

.index-module__XwIKRG__itemActive {
  --wcom-border-color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
  border: 1px solid var(--wcom-border-color);
  background-color: var(--lightningcss-light, var(--wcom-c-primary-container-light)) var(--lightningcss-dark, var(--wcom-c-primary-container-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
}

.index-module__XwIKRG__overlayTrigger {
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
  white-space: nowrap;
  gap: 4px;
  padding: 5px;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  position: relative;
}

.index-module__XwIKRG__overlayTriggerOpen {
  --wcom-border-color: var(--lightningcss-light, var(--wcom-c-outline-variant-light)) var(--lightningcss-dark, var(--wcom-c-outline-variant-dark));
  --wcom-border-bottom-color: var(--lightningcss-light, var(--wcom-c-feature-blue-light)) var(--lightningcss-dark, var(--wcom-c-feature-blue-dark));
  z-index: 2;
  border: 1px solid var(--wcom-border-color);
  border-bottom: 1px solid var(--wcom-border-bottom-color);
  border-radius: 16px 16px 0 0;
}

.index-module__XwIKRG__locationLabel {
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
}

.index-module__XwIKRG__list {
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

@media (min-width: 768px) {
  .index-module__XwIKRG__list {
    flex-flow: wrap;
    justify-content: space-between;
    gap: 10px;
    padding: 0 10px 10px;
  }
}

.filterAndSort-module__UNtFlW__bar {
  justify-content: space-between;
  align-items: center;
  margin: 10px 10px 8px;
  display: flex;
  position: relative;
}

.filterAndSort-module__UNtFlW__flexbox {
  display: flex;
}

.filterAndSort-module__UNtFlW__activeFilters {
  padding: 0 10px 12px;
}

.filterAndSort-module__UNtFlW__sliderContent {
  gap: 8px;
  display: flex;
}

.filterAndSort-module__UNtFlW__deleteFilterIcon {
  color: var(--lightningcss-light, var(--wcom-c-outline-variant-light)) var(--lightningcss-dark, var(--wcom-c-outline-variant-dark));
  margin-left: 5px;
  font-size: 10px;
}

.accordion-module__CklU2W__wrapper {
  border-bottom: 1px solid var(--wcom-c-blackAlpha20);
  background-color: var(--wcom-c-white);
  color: var(--wcom-c-black);
  white-space: pre-wrap;
  padding: 0;
  font-size: 12px;
  line-height: normal;
}

.accordion-module__CklU2W__wrapper:last-child {
  border-bottom: none;
}

.accordion-module__CklU2W__wrapperSub {
  border-bottom: 1px solid var(--wcom-c-white);
  background-color: var(--wcom-c-grayLighter);
  padding: 0 10px;
}

.accordion-module__CklU2W__headlineWrapper {
  all: unset;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
  font-weight: 600;
  display: flex;
}

.accordion-module__CklU2W__headline {
  flex: 7;
}

.accordion-module__CklU2W__arrow {
  color: var(--wcom-c-blueDark);
  text-align: right;
  flex: 1;
  align-self: center;
  font-size: 10px;
  transform: rotate(0);
}

.accordion-module__CklU2W__arrowIsOpen {
  text-align: left;
  transform: rotate(180deg);
}

.accordion-module__CklU2W__text {
  padding-bottom: 20px;
}

.accordion-module__CklU2W__headlineSub {
  font-weight: 600;
}

.accordion-module__CklU2W__textSub {
  padding-bottom: 10px;
}

.accordion-module__CklU2W__ulSub {
  margin: 0;
  padding: 0 15px 10px;
}

.accordion-module__CklU2W__aSub {
  color: var(--wcom-c-blueDark);
  font-weight: 600;
  text-decoration: none;
}

:root {
  --wcom-font-size-mobile: 12px;
  --wcom-line-height-mobile: 16px;
}

.styles-module__urVvPa__wrapper {
  z-index: var(--wcom-z-cmp);
  background-color: var(--wcom-c-white);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
}

@media (min-width: 426px) {
  .styles-module__urVvPa__wrapper {
    left: 10%;
    right: 10%;
  }
}

@media (min-width: 768px) {
  .styles-module__urVvPa__wrapper {
    height: 100%;
    min-height: auto;
    left: 25%;
    right: 25%;
  }
}

.styles-module__urVvPa__header {
  z-index: 1;
  background-color: var(--wcom-c-white);
  width: 100%;
  color: var(--wcom-c-blueDark);
  justify-content: space-between;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  position: fixed;
}

@media (min-width: 426px) {
  .styles-module__urVvPa__header {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .styles-module__urVvPa__header {
    width: 50%;
  }
}

.styles-module__urVvPa__headerWithLogo {
  padding: 0;
}

.styles-module__urVvPa__logoWrapper {
  text-align: center;
  flex: 1;
  justify-content: center;
  padding: 30px 0;
  display: flex;
}

.styles-module__urVvPa__contentWrapper {
  padding: 41px 20px 60px;
}

.styles-module__urVvPa__contentWrapperWithLogo {
  padding-top: 100px;
}

.styles-module__urVvPa__contentWrapperWithButtons {
  padding-bottom: 125px;
}

.styles-module__urVvPa__title {
  color: var(--wcom-c-blueDark);
  font-size: 20px;
  font-weight: 700;
  line-height: inherit;
  margin-bottom: 10px;
}

.styles-module__urVvPa__subTitle {
  color: var(--wcom-c-blueDark);
  margin-bottom: 20px;
  font-size: 14px;
}

.styles-module__urVvPa__subSubTitle {
  font-size: 15px;
  font-weight: 600;
  line-height: initial;
  margin-bottom: 15px;
}

.styles-module__urVvPa__buttonInline {
  all: unset;
  color: var(--wcom-c-blueDark);
  font-weight: 600;
  text-decoration: none;
}

.styles-module__urVvPa__buttonCmp {
  margin-bottom: 20px;
  font-weight: 700;
}

.styles-module__urVvPa__buttonCmpWrapper {
  border-top: 1px solid var(--wcom-c-blackAlpha20);
  background-color: var(--wcom-c-white);
  width: inherit;
  padding: 20px 20px 0;
  position: fixed;
  bottom: 40px;
}

.styles-module__urVvPa__spacer {
  margin-bottom: 15px;
}

.styles-module__urVvPa__buttonCoPaWrapper {
  flex-direction: row;
  display: flex;
}

.styles-module__urVvPa__buttonCoPaWrapper div {
  display: inline-block;
}

.styles-module__urVvPa__arrowRight {
  font-size: 8px;
  display: inline-block;
  transform: rotate(270deg);
}

.styles-module__urVvPa__arrowLeft {
  font-size: 8px;
  display: inline-block;
  transform: rotate(90deg);
}

.styles-module__urVvPa__text {
  padding-bottom: initial;
  width: 100%;
  color: var(--wcom-c-black);
  border-bottom: none;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: normal;
  display: block;
}

.styles-module__urVvPa__text a {
  color: var(--wcom-c-blueDark);
  font-weight: 600;
  text-decoration: none;
}

.styles-module__urVvPa__textRight {
  text-align: right;
}

.styles-module__urVvPa__separator10 {
  border-bottom: 1px solid var(--wcom-c-blackAlpha20);
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.styles-module__urVvPa__separator20 {
  border-bottom: 1px solid var(--wcom-c-blackAlpha20);
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.styles-module__urVvPa__textSpacerRight {
  margin-right: 4px;
}

.styles-module__urVvPa__copaLogoInline {
  margin-right: 5px;
}

:root {
  --wcom-font-size-mobile: 12px;
  --wcom-line-height-mobile: 16px;
}

.footer-module__PfatGG__footer {
  width: 100%;
  position: fixed;
  bottom: 0;
}

@media (min-width: 426px) {
  .footer-module__PfatGG__footer {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .footer-module__PfatGG__footer {
    width: 50%;
  }
}

.footer-module__PfatGG__linkWrapper {
  background-color: var(--wcom-c-blueMidDark);
  color: var(--wcom-c-white);
  text-align: center;
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
}

.footer-module__PfatGG__link {
  color: var(--wcom-c-white);
}

.styles-module__UK36zW__h1 {
  font-size: 24px;
  line-height: 32px;
}

.styles-module__UK36zW__section {
  padding: 20px 0;
}

.styles-module__UK36zW__link {
  color: var(--wcom-c-blueDark);
}

:root {
  --wcom-font-size-mobile: 12px;
  --wcom-line-height-mobile: 16px;
}

.styles-module__Qnjq4G__wrapper {
  gap: 10px;
  display: grid;
}

@media (min-width: 768px) {
  .styles-module__Qnjq4G__wrapper {
    grid-template-columns: repeat(3, 33%);
  }
}

.styles-module__Qnjq4G__box {
  flex-direction: column;
  margin-bottom: 16px;
  display: flex;
}

.styles-module__Qnjq4G__headline {
  margin-bottom: 10px;
  font-size: 22px;
  line-height: normal;
}

.styles-module__Qnjq4G__icon {
  color: var(--wcom-c-blueDark);
  margin-left: 5px;
}

.styles-module__Qnjq4G__section {
  font-size: var(--wcom-font-size-mobile);
  line-height: var(--wcom-line-height-mobile);
}

.content-module__cVEoyG__headline {
  margin-bottom: 10px;
  font-size: 15px;
}

.content-module__cVEoyG__container {
  border-spacing: 1px 0;
  background: unset;
  table-layout: fixed;
  white-space: normal;
  width: 100%;
  height: 50px;
  font-size: 12px;
  display: table;
  position: relative;
}

.content-module__cVEoyG__item {
  vertical-align: middle;
  cursor: pointer;
  background: linear-gradient(0deg, var(--wcom-c-blueGradient3), var(--wcom-c-blueGradient1) 50%, var(--wcom-c-blueGradient3));
  height: 50px;
  color: var(--wcom-c-white);
  border-radius: 2px;
  padding: 5px;
  font-weight: 700;
  display: table-cell;
}

.content-module__cVEoyG__item * {
  color: var(--wcom-c-white);
}

.content-module__cVEoyG__item span[class="icon-sun-hours"] {
  color: var(--wcom-c-yellow);
}

.content-module__cVEoyG__isActive {
  box-shadow: 1px 1px 1px var(--wcom-c-blueDarkestAlpha40);
  background: linear-gradient(0deg, var(--wcom-c-white), var(--wcom-c-blueLight) 50%, var(--wcom-c-white));
}

.content-module__cVEoyG__isActive * {
  color: var(--wcom-c-blueDark);
}

.content-module__cVEoyG__isLoading {
  display: none;
}

.content-module__cVEoyG__subtitle {
  font-weight: normal;
  display: block;
}

.top-module__N2jvpW__container {
  white-space: normal;
  width: 100%;
  height: 60px;
  font-size: 14px;
  position: relative;
}

.top-module__N2jvpW__containerBackground {
  background: var(--wcom-c-blueMid);
}

.top-module__N2jvpW__slider {
  gap: 2px;
  position: relative;
  display: flex !important;
}

.top-module__N2jvpW__item {
  cursor: pointer;
  background: linear-gradient(0deg, var(--wcom-c-blueGradient3), var(--wcom-c-blueGradient1) 50%, var(--wcom-c-blueGradient3));
  width: 100%;
  height: 60px;
  color: var(--wcom-c-white);
  border-radius: 2px;
  padding: 10px;
  font-weight: 700;
  display: inline-flex;
}

.top-module__N2jvpW__item > * {
  width: 100%;
  height: 100%;
  color: var(--wcom-c-white);
}

.top-module__N2jvpW__itemModern {
  cursor: pointer;
  background-color: var(--lightningcss-light, var(--wcom-c-secondary-container-light)) var(--lightningcss-dark, var(--wcom-c-secondary-container-dark));
  border-radius: 8px;
  justify-content: center;
  width: 150px;
  height: 60px;
  padding: 10px;
  font-weight: 700;
  display: inline-flex;
}

.top-module__N2jvpW__itemModern > * {
  width: 100%;
  height: 100%;
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
  font-weight: 700;
}

.top-module__N2jvpW__isActive {
  background: linear-gradient(0deg, var(--wcom-c-white), var(--wcom-c-blueLight) 50%, var(--wcom-c-white));
}

.top-module__N2jvpW__isActive > * {
  color: var(--wcom-c-blueDark);
}

.top-module__N2jvpW__isActiveModern {
  background: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
}

.top-module__N2jvpW__isActiveModern * {
  color: var(--lightningcss-light, var(--wcom-c-secondary-container-light)) var(--lightningcss-dark, var(--wcom-c-secondary-container-dark));
}

.top-module__N2jvpW__isLoading {
  visibility: hidden;
  margin-top: -60px;
}

.top-module__N2jvpW__itemInner {
  text-align: center;
  white-space: nowrap;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.top-module__N2jvpW__itemInner > span {
  font-weight: 400;
}

.styles-module__mYEFjG__arrowIcon {
  margin-left: 5px;
  font-size: 8px;
}

.styles-module__mYEFjG__arrowIconOpen {
  transform: rotate(180deg);
}

.styles-module__mYEFjG__styledButton {
  margin-bottom: 20px;
}

.styles-module__mYEFjG__hidden {
  display: none;
}

.styles-module__mYEFjG__table {
  border-right: 1px solid var(--wcom-c-gray);
  border-left: 1px solid var(--wcom-c-gray);
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  font-size: 12px;
  line-height: 20px;
}

.styles-module__mYEFjG__textCenter {
  text-align: center;
}

.styles-module__mYEFjG__td {
  vertical-align: top;
  border-left: 1px solid var(--wcom-c-gray);
  padding: 0 5px 10px;
}

.styles-module__mYEFjG__tdDayChange {
  border-left: 1px solid var(--wcom-c-blueDark);
}

.styles-module__mYEFjG__tdFontBold {
  padding-bottom: 0;
  font-weight: bold;
}

.styles-module__mYEFjG__tdFontBlue {
  color: var(--wcom-c-blueDark);
}

.styles-module__mYEFjG__temperature {
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  display: flex;
}

.styles-module__mYEFjG__temperature span:first-child {
  font-size: 18px;
}

.styles-module__mYEFjG__detailContainer {
  flex-direction: row;
  display: flex;
}

.styles-module__mYEFjG__detailContainerIcon {
  flex: 1;
  justify-content: center;
  padding-right: .3rem;
  display: flex;
}

.styles-module__mYEFjG__detailContainerText {
  text-align: left;
  flex: 2;
}

.styles-module__mYEFjG__colorBlue {
  color: var(--wcom-c-blueDark);
}

.styles-module__mYEFjG__colorRed {
  color: var(--wcom-c-red);
}

.styles-module__qv0mcG__icon {
  display: block;
}

.index-module__eqVwOa__scrollContainer {
  width: 100%;
  overflow-x: scroll;
}

.index-module__eqVwOa__table {
  border-collapse: collapse;
  table-layout: fixed;
  font-size: 12px;
}

.index-module__eqVwOa__tableRowRelative {
  position: relative;
}

.index-module__eqVwOa__description {
  margin-top: 20px;
  font-size: 12px;
}

.index-module__eqVwOa__td {
  vertical-align: top;
  border-right: 1px solid var(--wcom-c-gray);
  border-left: 1px solid var(--wcom-c-gray);
  text-align: center;
  height: 10px;
  padding: 0;
}

.index-module__eqVwOa__tdHeight18 {
  height: 18px;
}

.index-module__eqVwOa__tdHeight25 {
  height: 25px;
}

.index-module__eqVwOa__tdHeight70 {
  height: 70px;
}

.index-module__eqVwOa__tdNoBorder {
  border: none;
}

.index-module__eqVwOa__tdHeading {
  align-items: center;
  height: 30px;
  display: flex;
}

.index-module__eqVwOa__tdRelative {
  position: relative;
}

.index-module__eqVwOa__tdVerticalAlignMiddle {
  vertical-align: middle;
}

.index-module__eqVwOa__tdVerticalAlignBottom {
  vertical-align: bottom;
}

.index-module__eqVwOa__windIcon {
  color: var(--wcom-c-yellow);
  font-size: .6rem;
}

.index-module__eqVwOa__pressureIcon {
  color: var(--wcom-c-blueDark);
}

.index-module__eqVwOa__gustsIcon {
  color: var(--wcom-c-red);
  display: block;
}

.index-module__eqVwOa__heading {
  position: absolute;
}

.index-module__eqVwOa__trAstronomy {
  height: 20px;
  position: relative;
}

.index-module__eqVwOa__tdAstronomy {
  white-space: nowrap;
  position: relative;
}

.index-module__eqVwOa__tdAstronomyIcon {
  color: var(--wcom-c-yellow);
}

.index-module__eqVwOa__tdAstronomyLabel {
  position: absolute;
  top: 0;
}

.index-module__eqVwOa__dates {
  border: none;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.index-module__eqVwOa__times {
  background-color: var(--wcom-c-white);
  position: absolute;
  top: 0;
  left: 100%;
  transform: translateX(-50%);
}

.index-module__eqVwOa__gusts {
  margin: 5px 0 10px;
}

.index-module__eqVwOa__precSum {
  background-color: var(--wcom-c-blueDark);
}

.index-module__eqVwOa__relativeHumidity {
  background-color: var(--wcom-c-blueMidLight);
}

.index-module__eqVwOa__cloudColor0 {
  background-color: #fff;
}

.index-module__eqVwOa__cloudColor1 {
  background-color: #efefef;
}

.index-module__eqVwOa__cloudColor2 {
  background-color: #dfdfdf;
}

.index-module__eqVwOa__cloudColor3 {
  background-color: #cfcfcf;
}

.index-module__eqVwOa__cloudColor4 {
  background-color: #bfbfbf;
}

.index-module__eqVwOa__cloudColor5 {
  background-color: #adadad;
}

.index-module__eqVwOa__cloudColor6 {
  background-color: #a0a0a0;
}

.index-module__eqVwOa__cloudColor7 {
  background-color: #8f8f8f;
}

.index-module__eqVwOa__cloudColor8 {
  background-color: gray;
}

.index-module__eqVwOa__clouds {
  height: 100%;
}

.styles-module__zxw2Lq__tabContainer {
  grid-column-gap: 5px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: 34px;
  margin-bottom: 10px;
  display: flex;
  overflow: scroll;
}

.styles-module__zxw2Lq__tabContainer::-webkit-scrollbar {
  display: none;
}

.styles-module__zxw2Lq__tabContainer a {
  line-height: 34px;
}

.styles-module__zxw2Lq__tab {
  cursor: pointer;
  box-shadow: 1px 1px 1px var(--wcom-c-blueDarkestAlpha40);
  background: var(--wcom-c-blueDark) linear-gradient(0deg, var(--wcom-c-blueGradient3), var(--wcom-c-blueGradient1) 50%, var(--wcom-c-blueGradient3));
  color: var(--wcom-c-white);
  border-radius: 1px;
  align-items: center;
  margin-bottom: 3px;
  padding: 6px 10px;
  font-size: 14px;
  text-decoration: none;
  display: flex;
}

.styles-module__zxw2Lq__tab > span {
  margin-right: 5px;
  font-size: 14px;
}

.styles-module__zxw2Lq__selected {
  background: var(--wcom-c-white) linear-gradient(0deg, var(--wcom-c-white), var(--wcom-c-bluelighter) 50%, var(--wcom-c-white));
  color: var(--wcom-c-blueDark);
}

.styles-module__zxw2Lq__diagramWrapper {
  min-height: 900px;
}

.styles-module__05Ve-a__warning {
  border-radius: 8px;
  align-items: center;
  padding: 4px 8px;
  font-size: 12px;
  display: inline-flex;
}

.styles-module__05Ve-a__icon {
  margin-right: 5px;
}

.styles-module__05Ve-a__level1 {
  background-color: var(--lightningcss-light, var(--wcom-c-favourable-container-light)) var(--lightningcss-dark, var(--wcom-c-favourable-container-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-favourable-container-light)) var(--lightningcss-dark, var(--wcom-c-on-favourable-container-dark));
}

.styles-module__05Ve-a__level2 {
  background-color: var(--lightningcss-light, var(--wcom-c-tolerable-container-light)) var(--lightningcss-dark, var(--wcom-c-tolerable-container-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-tolerable-container-light)) var(--lightningcss-dark, var(--wcom-c-on-tolerable-container-dark));
}

.styles-module__05Ve-a__level3 {
  background-color: var(--lightningcss-light, var(--wcom-c-difficult-container-light)) var(--lightningcss-dark, var(--wcom-c-difficult-container-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-difficult-container-light)) var(--lightningcss-dark, var(--wcom-c-on-difficult-container-dark));
}

.styles-module__05Ve-a__level4 {
  background-color: var(--lightningcss-light, var(--wcom-c-danger-container-light)) var(--lightningcss-dark, var(--wcom-c-danger-container-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-danger-container-light)) var(--lightningcss-dark, var(--wcom-c-on-danger-container-dark));
}

.styles-module__05Ve-a__level5 {
  background-color: var(--lightningcss-light, var(--wcom-c-extreme-container-light)) var(--lightningcss-dark, var(--wcom-c-extreme-container-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
}

:root {
  --wcom-font-size-mobile: 12px;
  --wcom-line-height-mobile: 16px;
}

.styles-module__5HL1IW__main, .styles-module__5HL1IW__mainAlternate, .styles-module__5HL1IW__mainError {
  background-color: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  max-width: 100vw;
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  transition: filter .5s;
}

@media (min-width: 768px) {
  .styles-module__5HL1IW__main, .styles-module__5HL1IW__mainAlternate, .styles-module__5HL1IW__mainError {
    flex-direction: column;
    justify-content: center;
    width: 768px;
    margin: 0 auto;
    display: flex;
  }
}

.styles-module__5HL1IW__mainError {
  min-height: 50vh;
  padding: 20px 10px;
}

.styles-module__5HL1IW__mainAlternate {
  background-color: var(--lightningcss-light, var(--wcom-c-feature-blue-light)) var(--lightningcss-dark, var(--wcom-c-feature-blue-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
}

.styles-module__5HL1IW__blueDivider {
  background: var(--wcom-c-blueMid);
  height: 20px;
}

.styles-module__5HL1IW__section {
  margin: 0 10px;
  padding: 30px 0;
  position: relative;
}

.styles-module__5HL1IW__section2 {
  margin: 0 10px;
  position: relative;
}

.styles-module__5HL1IW__unstyled {
  all: unset;
}

.styles-module__2-WL2q__text {
  margin-top: 20px;
  font-size: 12px;
}

.styles-module__bHQ87W__text {
  margin-bottom: 10px;
}

.styles-module__bHQ87W__text:last-child {
  border-bottom: 1px solid var(--wcom-c-gray);
  padding-bottom: 10px;
}

.styles-module__65E8AG__itemHeadlineBlack {
  color: #27313f;
  margin: 0 0 10px;
  font-size: 14px;
}

.styles-module__65E8AG__itemReadMoreLink {
  color: #054798;
  margin: 10px 0 0;
  font-size: 14px;
  display: block;
}

.styles-module__65E8AG__itemReadMoreLink > [class^="icon"] {
  margin-left: 10px;
}

.styles-module__65E8AG__itemWrapper {
  border-bottom: 1px solid #e3e7ed;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0;
  padding: 10px 0;
  display: flex;
}

.styles-module__65E8AG__itemHeadline {
  color: #054798;
  margin: 15px 0 10px;
  font-size: 14px;
}

.styles-module__65E8AG__itemText {
  color: #27313f;
  font-size: 12px;
}

.styles-module__65E8AG__itemReadMore {
  color: #054798;
  margin: 10px 0 0;
  font-size: 14px;
}

.styles-module__65E8AG__itemReadMore > [class^="icon"] {
  margin-left: 10px;
}

.styles-module__65E8AG__tabWrapper {
  grid-template-columns: repeat(auto-fill, 40px);
  justify-content: center;
  display: grid;
}

.styles-module__65E8AG__tabItem {
  color: #27313f;
  background: #cce3ff;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 5px;
  font-size: 18px;
  display: flex;
}

.styles-module__65E8AG__tabItemActive {
  color: var(--wcom-c-white);
  background: #054798;
}

.styles-module__65E8AG__tabItemDisabled {
  color: #27313f;
  background: #e3e7ed;
}

.pollenDictionary-module__NNtWTa__h1 {
  border-top: 1px solid var(--wcom-c-blueMid);
  background: var(--wcom-c-blueMid);
  color: var(--wcom-c-white);
  align-items: center;
  margin-bottom: 0;
  padding: 10px;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  display: flex;
  position: relative;
}

.styles-module__979OEW__lastUpdated {
  text-align: right;
}

.styles-module__979OEW__buttonContainer {
  text-align: center;
  height: 40px;
}

.styles-module__979OEW__openCmpLink {
  all: unset;
  color: var(--wcom-c-blueDark);
}

.styles-module__979OEW__faqSection {
  margin-top: 20px;
}

a {
  color: var(--wcom-c-blueDark);
}

ul {
  font-size: var(--wcom-font-size-mobile);
  line-height: var(--wcom-line-height-mobile);
}

.styles-module__eRACgq__wrapper {
  opacity: 1;
  z-index: var(--wcom-z-toast);
  border: 2px solid var(--lightningcss-light, var(--wcom-c-success-container-light)) var(--lightningcss-dark, var(--wcom-c-success-container-dark));
  background-color: var(--lightningcss-light, var(--wcom-c-success-light)) var(--lightningcss-dark, var(--wcom-c-success-dark));
  width: 90vw;
  min-height: 50px;
  color: var(--lightningcss-light, var(--wcom-c-success-container-light)) var(--lightningcss-dark, var(--wcom-c-success-container-dark));
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: all .5s ease-in-out;
  display: flex;
  position: fixed;
  top: 60px;
  left: 5vw;
}

.styles-module__eRACgq__closed {
  opacity: 0;
  z-index: -1;
  transition: all .5s ease-in-out;
  top: 0;
}

.styles-module__CvBuWW__wrapper {
  background-color: var(--lightningcss-light, var(--wcom-c-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-surface-variant-dark));
  border-radius: 12px;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 5px;
  padding: 12px 16px;
  font-size: 15px;
  line-height: 20px;
  display: flex;
}

.styles-module__CvBuWW__questionWrapper {
  justify-content: space-between;
  width: 100%;
  display: flex;
  position: relative;
}

.styles-module__CvBuWW__question {
  width: calc(100% - 30px);
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
}

.styles-module__CvBuWW__questionOpen {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-variant-dark));
}

.styles-module__CvBuWW__answerHidden {
  display: none;
}

.styles-module__CvBuWW__icon {
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
  flex-shrink: 0;
  font-size: 10px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%)rotate(0);
}

.styles-module__CvBuWW__iconOpen {
  transform: translateY(-50%)rotate(180deg);
}

.styles-module__CvBuWW__privacyTable {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: var(--wcom-font-size-mobile);
  line-height: var(--wcom-line-height-mobile);
}

.styles-module__CvBuWW__privacyTable td {
  vertical-align: top;
  border: 1px solid var(--wcom-c-grayDark);
}

.styles-module__CvBuWW__privacyTableCol1 {
  width: 5%;
}

.styles-module__CvBuWW__privacyTableCol2, .styles-module__CvBuWW__privacyTableCol3 {
  width: 47%;
}

.styles-module__CvBuWW__tableOfContents {
  margin-bottom: 20px;
}

.styles-module__CvBuWW__tableOfContentsLink {
  font-size: 14px;
  display: block;
}

.styles-module__CvBuWW__indented {
  margin-left: 20px;
}

.index-module__OC2b3q__wrapper {
  background: var(--lightningcss-light, var(--wcom-c-feature-blue-light)) var(--lightningcss-dark, var(--wcom-c-feature-blue-dark));
  padding: 20px 0;
}

.index-module__OC2b3q__subItems {
  position: relative;
}

.index-module__OC2b3q__item {
  background: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  border-radius: 12px;
  align-items: center;
  gap: 10px;
  height: 75px;
  padding: 0 15px;
  display: flex;
}

.index-module__OC2b3q__item:last-child {
  margin-bottom: 0;
}

.index-module__OC2b3q__dateWrapper {
  flex-direction: column;
  align-items: flex-start;
  min-width: 50px;
  display: flex;
}

.index-module__OC2b3q__temperatureMax {
  margin-right: 5px;
  font-size: 20px;
  font-weight: 600;
}

.index-module__OC2b3q__temperatureMin {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-variant-dark));
  font-size: 12px;
}

.index-module__OC2b3q__windchillMax {
  margin-right: 5px;
  font-size: 12px;
  font-weight: 600;
}

.index-module__OC2b3q__windchillMin {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-variant-dark));
  font-size: 12px;
}

.index-module__OC2b3q__temperatureWrapper {
  justify-content: space-between;
  align-items: center;
  min-width: 110px;
  display: flex;
}

.index-module__OC2b3q__weatherState {
  margin-right: 5px;
}

.index-module__OC2b3q__windWrapper {
  width: 100%;
}

.index-module__OC2b3q__windWrapper > div {
  display: flex;
}

.index-module__OC2b3q__precWrapper {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.index-module__OC2b3q__flex {
  display: flex;
}

.index-module__OC2b3q__fullWidth {
  width: 100%;
}

.index-module__OC2b3q__text {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-variant-dark));
  white-space: nowrap;
  font-size: 12px;
}

.index-module__OC2b3q__textDark {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  font-size: 12px;
}

.index-module__OC2b3q__textBold {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  font-size: 12px;
  font-weight: 600;
}

.index-module__OC2b3q__textSeparator {
  margin-right: 3px;
}

.item-module__IqHTQG__itemClose {
  justify-content: end;
  height: 50px;
}

.item-module__IqHTQG__itemWrapper {
  border-radius: 12px;
  margin-bottom: 5px;
}

.item-module__IqHTQG__itemWrapperOpen {
  background: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
}

.item-module__IqHTQG__item {
  background: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  border-radius: 12px;
  align-items: center;
  gap: 10px;
  height: 75px;
  padding: 0 15px;
  display: flex;
}

.item-module__IqHTQG__toggle {
  min-width: 20px;
}

.item-module__IqHTQG__toggleArrow {
  width: 20px;
  color: var(--lightningcss-light, var(--wcom-c-primary-light)) var(--lightningcss-dark, var(--wcom-c-primary-dark));
  font-size: 10px;
}

.item-module__IqHTQG__rotated {
  transform: rotate(180deg);
}

.item-module__IqHTQG__emptyField {
  min-width: 20px;
}

.subItem-module__MThPfa__item {
  background: var(--lightningcss-light, var(--wcom-c-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-surface-variant-dark));
  margin: 0 10px 5px;
  padding: 0 8px;
}

.legend-module__hxnISG__item {
  background: none;
  align-items: end;
  height: auto;
  margin-top: 20px;
  margin-bottom: 10px;
}

.legend-module__hxnISG__item * {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-variant-dark));
  font-size: 12px;
}

.legend-module__hxnISG__temperatureWrapper {
  text-align: right;
  justify-content: end;
}

.index-module__-llbTa__wrapper {
  padding-bottom: 20px;
}

.index-module__-llbTa__inner {
  gap: 4px;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 96px;
  padding: 0 10px;
  display: flex;
}

.index-module__-llbTa__item {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.index-module__-llbTa__time {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-variant-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-variant-dark));
  font-size: 15px;
  line-height: 20px;
}

.index-module__-llbTa__temperature {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  font-size: 16px;
  font-weight: 600;
}

.index-module__-llbTa__icon {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  font-size: 24px;
  font-weight: 600;
}

.index-module__KCfKRG__headline {
  padding: 10px;
}

.index-module__KCfKRG__toggle {
  gap: 8px;
  padding: 10px 10px 20px;
  display: flex;
}

.index-module__KCfKRG__toggleSun {
  position: absolute;
}

.index-module__KCfKRG__hidden {
  display: none;
}

.skiArea-module__MdOX3G__root {
  background-color: var(--lightningcss-light, var(--wcom-c-feature-blue-light)) var(--lightningcss-dark, var(--wcom-c-feature-blue-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
}

.skiArea-module__MdOX3G__root[data-support-color-scheme-light-and-dark="true"] {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light dark;
}

@media (prefers-color-scheme: dark) {
  .skiArea-module__MdOX3G__root[data-support-color-scheme-light-and-dark="true"] {
    --lightningcss-light: ;
    --lightningcss-dark: initial;
  }
}

.skiArea-module__MdOX3G__h1 {
  background: var(--lightningcss-light, var(--wcom-c-feature-blue-light)) var(--lightningcss-dark, var(--wcom-c-feature-blue-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  align-items: center;
  margin-bottom: 0;
  padding: 10px;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  display: flex;
  position: relative;
}

.skiArea-module__MdOX3G__h1Image {
  border: 2px solid var(--wcom-c-blueLight);
  border-radius: 12px;
  flex: none;
  margin-right: 10px;
}

.skiArea-module__MdOX3G__h2, .skiArea-module__MdOX3G__h3 {
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.skiArea-module__MdOX3G__section {
  background-color: var(--lightningcss-light, var(--wcom-c-feature-blue-light)) var(--lightningcss-dark, var(--wcom-c-feature-blue-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  margin-bottom: 30px;
  padding: 15px 10px;
}

.skiArea-module__MdOX3G__section2 {
  background-color: var(--lightningcss-light, var(--wcom-c-feature-blue-light)) var(--lightningcss-dark, var(--wcom-c-feature-blue-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  padding: 15px 0;
}

.skiArea-module__MdOX3G__section3 {
  background-color: var(--lightningcss-light, var(--wcom-c-feature-blue-light)) var(--lightningcss-dark, var(--wcom-c-feature-blue-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  padding: 15px 10px;
}

.skiArea-module__MdOX3G__section4 {
  margin: 0 10px;
  padding: 30px 0 0;
  position: relative;
}

.skiArea-module__MdOX3G__section5 {
  background-color: var(--lightningcss-light, var(--wcom-c-surface-light)) var(--lightningcss-dark, var(--wcom-c-surface-dark));
  color: var(--lightningcss-light, var(--wcom-c-on-surface-container-light)) var(--lightningcss-dark, var(--wcom-c-on-surface-container-dark));
  padding: 15px 0 30px;
}

